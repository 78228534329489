// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import './vendor-src/_functions';

// 2. Include any default variable overrides here
$primary: #6763b3;
$warning: #f4a86e;
$secondary: #f3f3f7;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1024px,
  xxl: 1025px,
);

// 3. Include remainder of required Bootstrap stylesheets
@import './vendor-src/_variables';

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import './vendor-src/_maps';
@import './vendor-src/_mixins';
@import './vendor-src/_root';

// 6. Optionally include any other parts as needed
@import './vendor-src/_utilities';

// Make width responsive
$utilities: map-merge(
  $utilities,
  (
    'width': map-merge(
        map-get($utilities, 'width'),
        (
          responsive: true,
          values: (
            0: 0%,
            5: 5%,
            10: 10%,
            15: 15%,
            20: 20%,
            25: 25%,
            30: 30%,
            35: 35%,
            40: 40%,
            45: 45%,
            50: 50%,
            55: 55%,
            60: 60%,
            65: 65%,
            70: 70%,
            75: 75%,
            80: 80%,
            85: 85%,
            90: 90%,
            95: 95%,
            100: 100%,
            auto: auto,
          ),
        )
      ),
  )
);

@import './vendor-src/_reboot';
@import './vendor-src/_type';
@import './vendor-src/_images';
@import './vendor-src/_containers';
@import './vendor-src/_grid';
@import './vendor-src/_helpers';
@import './vendor-src/_tables';
@import './vendor-src/_forms';
@import './vendor-src/_buttons';
@import './vendor-src/_transitions';
@import './vendor-src/_dropdown';
@import './vendor-src/_button-group';
@import './vendor-src/_nav';
@import './vendor-src/_navbar';
@import './vendor-src/_card';
@import './vendor-src/_accordion';
@import './vendor-src/_breadcrumb';
@import './vendor-src/_pagination';
@import './vendor-src/_badge';
@import './vendor-src/_alert';
@import './vendor-src/_progress';
@import './vendor-src/_list-group';
@import './vendor-src/_close';
@import './vendor-src/_toasts';
@import './vendor-src/_modal';
@import './vendor-src/_tooltip';
@import './vendor-src/_popover';
@import './vendor-src/_carousel';
@import './vendor-src/_spinners';
@import './vendor-src/_offcanvas';
@import './vendor-src/_placeholders';

// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import './vendor-src/utilities/_api';

// 8. Add additional custom code here

@import 'accordion';
@import 'card';
