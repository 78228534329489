.no-container-padding {
  margin-left: calc(var(--bs-gutter-x) * -0.5);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
}
.cursor-pointer {
  cursor: pointer;
}
.hoverable:hover {
  outline: 2px solid rgba(80, 192, 233, 0.2);
  box-shadow: 0 2px 14px rgb(151 167 173 / 32%);
  cursor: pointer;
}

.report-hide {
  display: none !important;
}

.inset-0 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.h-0 {
  height: 0 !important;
}
