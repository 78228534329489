// Styles for answer choice body - this needs to be in global stylesheet
// because of HTML rendering and CSS scoping with Angular
.choice-body {
  & > .text-center {
    text-align: left !important;
  }

  & > *:last-child {
    margin-bottom: 0 !important;
  }
}

kbd {
  font: inherit;
  background-color: inherit;
  color: inherit;
  padding: 0;
  margin: inherit;
}

.inline-content {
  p {
    display: inline;
  }
}
